<template>
  <div class="box">
    <div class="header">
      <div class="header-search">
        <van-nav-bar
          :title="title"
          left-text=""
          left-arrow
          @click-left="onClickLeft"
        >
        </van-nav-bar>
      </div>
    </div>
    <div class="content contentbox loadingbox" v-if="loadingfalg">
      <van-loading size="24px" vertical>加载中...</van-loading>
    </div>
    <div class="content-s" v-if="!loadingfalg">
      <div style="background-color: #ffffff; margin: 12px; padding: 18px; border-radius: 8px;" v-for="(item, index) in details" :key="index">
        <p style="font-size: 14px; margin-bottom: 12px; font-weight: 700">{{item.strprodname}}</p>
        <p style="margin-bottom: 8px;">物资编码：{{item.strprodcode}}</p>
        <p>数量：{{item.number}} {{item.measunit}}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loadingfalg: true,
      submitflag: false,
      show: true,
      showage: true,
      title: "物资信息",
      loading: false,
      finished: true,
      isLoading: false,
      active: "a",
      activeNames: ["1", "2", "3", "4", "5", "6"],
      details: [],
      levels: null,
      hierarchy: "",
      pageIndex: 1,
      pageSize: 10,
      
    };
  },
  async created() {

    this.Projectlist()
  },
  computed: {
    stockaffirmId() {
      return this.$route.query.id;
    },
    appStatus() {
      return this.$route.query.appStatus;
    },
  },
  methods: {
    async Projectlist() {
      // console.log('dddd', this.stockaffirmId, typeof this.stockaffirmId)
      const params = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        projectId: this.$route.query.projectId
      };
      let data = await this.$api.ec.Projectlist(params)
      if (data) {
        this.loadingfalg = false
      }

      this.details = data.data
    },
    onClickLeft() {
      if (this.showage) {
        this.$router.go(-1)
      } else {
        this.showage = true;
        this.title = "物资信息";
      }
    },
  },
};
</script>
<style lang="less" scoped>
.content-s {
  background-color: #f3f5fa;
  position: relative;
  top: 1.23rem;
  width: 100%;
  min-height: 10.66667rem;
  padding-bottom: 1.35rem;
}
.footer-left {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #70e6ea;
}
.footer-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #f77269;
}
/deep/.group {
  .van-cell {
    color: #8f8f8f;
  }
}
/deep/.van-cell {
  background-color: transparent;
}
/deep/.van-cell {
  font-size: 12px;
}
/deep/.van-collapse-item__content {
  font-size: 12px;
  padding: 0;
}
/deep/.van-cell__value {
  color: #333;
}
.supplies {
  margin-top: 10px;
  min-height: 160px;
  background-color: #fff;
  padding: 0.3rem;
  border-radius: 9px;
  margin: 0.3rem;
  .supplies-title {
    font-weight: 400;
    line-height: 25px;
    font-size: 13px;
  }
  .supplies-text {
    color: #8f8f8f;
    line-height: 25px;
  }
}
.record {
  margin-top: 10px;
}
</style>
